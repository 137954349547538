var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w3-center"},[_vm._m(0),_c('div',{staticClass:"w3-center",staticStyle:{"min-height":"44px"},attrs:{"id":"sign-in"}}),_c('hr'),_vm._m(1),_c('hr'),_c('div',{staticClass:"w3-row-padding"},[_c('div',{staticClass:"w3-half"},[_vm._m(2),_c('div',{staticClass:"w3-container"},[_c('p',[(_vm.auto_account_creation)?[_vm._v("You automatically receive a free personal registry when you first sign in.")]:[_vm._v("A free personal registry is available "),_c('router-link',{staticClass:"w3-text-theme",attrs:{"to":"/request"}},[_vm._v("on request")]),_vm._v(".")],_vm._v(" Personal registries are visible only to your account. ")],2)])]),_vm._m(3)]),_c('div',{staticClass:"w3-row-padding"},[_vm._m(4),_c('div',{staticClass:"w3-half"},[_vm._m(5),_c('div',{staticClass:"w3-container"},[_c('p',[(_vm.auto_account_creation)?[_vm._v("Just sign in for a free personal registry!")]:[_vm._v("Personal registries are free "),_c('router-link',{staticClass:"w3-text-theme",attrs:{"to":"/request"}},[_vm._v("on request")]),_vm._v(".")],_c('br'),_c('router-link',{staticClass:"w3-text-theme",attrs:{"to":"/contact"}},[_vm._v("Contact us")]),_vm._v(" to enquire about shared registry pricing. ")],2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w3-center w3-padding-32"},[_c('img',{attrs:{"id":"logo","src":"/inverse-logo.svg","alt":"Logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w3-padding feature-text"},[_c('p',[_vm._v(" RGB Assurance's Signature Registry is an easy to use registry for handwritten signatures. When you register a signature, a unique code is randomly generated for that particular document, signed on that particular day. You then write the code alongside your handwritten signature. It's that simple. ")]),_c('p',[_vm._v(" This quick process verifies each signature that you sign, and ensures that it can't be copied without your consent. The Signature Registry acts as a valuable tool to verify the authenticity of your signatures. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w3-container"},[_c('h3',{staticClass:"w3-text-theme"},[_vm._v("Personal Registry")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w3-half"},[_c('div',{staticClass:"w3-container"},[_c('h3',{staticClass:"w3-text-theme"},[_vm._v("Shared Registries")])]),_c('div',{staticClass:"w3-container"},[_c('p',[_vm._v("Businesses can share registries with their employees via our flexible and affordable subscription plans. At any time, users can download their registries for reporting, evidence, and archival purposes. Shared registry users also have the ability to upload documents alongside their signatures, to be stored securely within the registry.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w3-half"},[_c('div',{staticClass:"w3-container"},[_c('h3',{staticClass:"w3-text-theme"},[_vm._v("Mobile Friendly")])]),_c('div',{staticClass:"w3-container"},[_c('p',[_vm._v("RGB Assurance's Signature Registry is quick and easy to use on mobile, so you can sign documents when you're out of the office.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w3-container"},[_c('h3',{staticClass:"w3-text-theme"},[_vm._v("Pricing")])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <h2 class="w3-center">{{ registry_name }}</h2>
    <div v-if="registry_owner" class="w3-row">
      <div v-if="!is_personal" class="w3-half w3-padding w3-center">
        <router-link :to="{ name: 'manage', params: {registry_id: registry_id}}" class="w3-button w3-theme-dark w3-hover-theme w3-round">Manage</router-link>
      </div>
      <div class="w3-padding w3-center" v-bind:class="{ 'w3-half': !is_personal }">
        <button class="w3-button w3-theme-dark w3-hover-theme w3-round" v-on:click="download" :disabled="downloading">Download</button>
      </div>
    </div>
    <div id="sign-form">
      <div class="w3-border w3-center w3-light-grey">
        <div class="w3-container w3-theme">
          <h4>Register a Signature</h4>
        </div>
        <form v-on:submit.prevent="sign" class="w3-container w3-padding">
          <input v-model="name" class="w3-input w3-border w3-center" type="text" placeholder="Document Name (required)" title="Document Name" required>
          <div class="w3-row">
            <div class="w3-half">
              <input v-model="document_id" class="w3-input w3-border w3-center" type="text" placeholder="Document ID" title="Document ID">
            </div>
            <div class="w3-half">
              <input v-model="revision" class="w3-input w3-border w3-center" type="text" placeholder="Revision" title="Revision">
            </div>
          </div>
          <div class="w3-row">
            <div class="w3-third">
              <input id="date-input" v-model="document_date" class="w3-input w3-border w3-center" type="date" title="Document Date">
            </div>
            <div class="w3-third">
              <input v-model="role" class="w3-input w3-border w3-center" type="text" placeholder="Role" title="Role">
            </div>
            <div class="w3-third">
              <input v-model="behalf_of" class="w3-input w3-border w3-center" type="text" placeholder="On Behalf Of" title="On Behalf Of">
            </div>
          </div>
          <div class="w3-padding"></div>
          <div class="w3-row">
            <div class="w3-half">
              <label><input v-model="enable_verification" class="w3-check" type="checkbox">&nbsp;Create Verification Link</label>
            </div>
            <div class="w3-half">
              <label>Expiry Date (if applicable):&nbsp;<input v-model="expiry_date" class="w3-border w3-center" type="date" title="Expiry Date"></label>
            </div>
          </div>
          <div v-if="!is_personal" class="w3-padding">
            <input id="file_upload" type="file" accept=".png,.jpg,.jpeg,.pdf,.docx,.doc" v-on:change="uploadSelected" style="display:none;">
            <input class="w3-button w3-theme-dark w3-hover-theme w3-round w3-center action-btn" type="button" v-on:click="selectUpload" :value="upload_btn_text">
            &nbsp;
            <button id="sign-btn" class="w3-button w3-theme-dark w3-hover-amber w3-round w3-center action-btn" type="submit">Sign</button>
          </div>
          <div v-else class="w3-padding">
            <button id="sign-btn" class="w3-button w3-theme-dark w3-hover-amber w3-round w3-center action-btn" type="submit">Sign</button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="signature_ids.length > 0" class="w3-responsive">
      <table class="table-align-middle w3-table-all w3-centered">
        <tr class="w3-theme">
          <th class="w3-left-align" v-if="registry_owner">User</th>
          <th class="w3-left-align">Document Name</th>
          <th class="w3-hide-small">Document ID</th>
          <th>Revision</th>
          <th>Date</th>
          <th class="w3-hide-small w3-hide-medium">Role</th>
          <th class="w3-hide-small w3-hide-medium">On Behalf Of</th>
          <th>Code</th>
          <th class="w3-hide-small">File</th>
          <th>Expiry</th>
          <th></th>
          <th></th>
        </tr>
        <tr v-for="id in signature_ids" :key="id">
          <td class="w3-left-align" v-if="registry_owner" v-bind:class="{ revoked: signatures[id].revoked }">{{ signatures[id].user_name }}</td>
          <td class="w3-left-align" v-bind:class="{ revoked: signatures[id].revoked }">{{ signatures[id].name }}</td>
          <td class="w3-hide-small" v-if="signatures[id].document_id" v-bind:class="{ revoked: signatures[id].revoked }">{{ signatures[id].document_id }}</td>
          <td class="w3-hide-small" v-else v-bind:class="{ revoked: signatures[id].revoked }"><i>N/A</i></td>
          <td v-if="signatures[id].revision" v-bind:class="{ revoked: signatures[id].revoked }">{{ signatures[id].revision }}</td>
          <td v-else v-bind:class="{ revoked: signatures[id].revoked }"><i>N/A</i></td>
          <td v-if="signatures[id].document_date" v-bind:class="{ revoked: signatures[id].revoked }">{{ signatures[id].document_date | formatDateTimestamp }}</td>
          <td v-else v-bind:class="{ revoked: signatures[id].revoked }"><i>N/A</i></td>
          <td class="w3-hide-small w3-hide-medium" v-if="signatures[id].role" v-bind:class="{ revoked: signatures[id].revoked }">{{ signatures[id].role }}</td>
          <td class="w3-hide-small w3-hide-medium" v-else v-bind:class="{ revoked: signatures[id].revoked }"><i>N/A</i></td>
          <td class="w3-hide-small w3-hide-medium" v-if="signatures[id].behalf_of" v-bind:class="{ revoked: signatures[id].revoked }">{{ signatures[id].behalf_of }}</td>
          <td class="w3-hide-small w3-hide-medium" v-else v-bind:class="{ revoked: signatures[id].revoked }"><i>N/A</i></td>
          <td v-bind:class="{ revoked: signatures[id].revoked }"><div class="code w3-wide w3-round"><h5><b>{{ signatures[id].code }}</b></h5></div></td>
          <td class="w3-hide-small">
            <a v-if="signatures[id].filename" :href="downloadUrl(signatures[id].filename)" rel="noopener" target="_blank">
              <i class="fa fa-file"></i>
            </a>
            <i v-else>N/A</i>
          </td>
          <td v-if="signatures[id].expiry_date !== null" v-bind:class="{ revoked: signatures[id].revoked }">{{ signatures[id].expiry_date | formatDateTimestamp }}</td>
          <td v-else v-bind:class="{ revoked: signatures[id].revoked }"><i>N/A</i></td>
          <td>
            <router-link v-if="signatures[id].verification_id" :to="{ name: 'verify', params: {verification_id: signatures[id].verification_id}}" target="_blank">
              <i class="fa fa-link w3-large"></i>
            </router-link>
          </td>
          <td class="w3-right-align">
            <button v-if="!signatures[id].revoked && signatures[id].owned" v-on:click="revoke(id)" class="w3-button w3-round w3-theme-dark w3-hover-theme">Revoke</button>
            <span v-if="signatures[id].revoked">Revoked<br>{{ signatures[id].revoked_date | formatDateTimeTimestamp }}</span>
          </td>
        </tr>
      </table>
      <div v-if="!all_loaded" class="w3-center w3-padding">
        <button v-on:click="fetchNext(false)" class="w3-button w3-circle w3-theme"><i class="fa fa-caret-down"></i></button>
      </div>
    </div>
    <div v-else>
      <p class="w3-center"><i>No signatures to show you (yet).</i></p>
    </div>
  </div>
</template>

<style scoped>
  #sign-form {
    padding-top: 16px;
    padding-bottom: 32px;
  }
  #date-input {
    padding: 7px;
  }
  .action-btn {
    min-width: 10%;
  }
  .code {
    background-image: url('/serial.png');
  }
  .revoked {
    text-decoration: line-through;
  }
</style>

<script>
export default {
  data: function () {
    return {
      name: '',
      document_id: '',
      revision: '',
      role: '',
      behalf_of: '',
      expiry_date: null,
      enable_verification: false,
      file: null,
      document_date: getDefaultDate(),
      showing_batch: 0,
      downloading: false,
      upload_btn_text: 'Upload File'
    }
  },
  mounted() {
    this.fetchNext(true);
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    registry_id() {
      return this.$route.params.registry_id;
    },
    is_personal() {
      if (this.$store.state.registries[this.registry_id]) {
        return this.$store.state.registries[this.registry_id].name === 'Personal Registry';
      } else {
        return true;
      }
    },
    signatures() {
      if (this.$store.state.signatures[this.registry_id]) {
        return this.$store.state.signatures[this.registry_id];
      } else {
        return {};
      }
    },
    signature_ids() {
      if (this.$store.state.signatures[this.registry_id]) {
        // default is to sort by string representation, so make integers then sort by value
        return Object.keys(this.$store.state.signatures[this.registry_id])
          .map(item => parseInt(item, 10))
          .sort((a, b) => a - b)
          .reverse();
      } else {
        return [];
      }
    },
    registry_name() {
      if (this.$store.state.registries[this.registry_id]) {
        return this.$store.state.registries[this.registry_id].name;
      }
    },
    registry_owner() {
      if (this.$store.state.registries[this.registry_id]) {
        return this.$store.state.registries[this.registry_id].owned;
      }
    },
    all_loaded() {
      if (this.$store.state.registries[this.registry_id]) {
        let total = this.$store.state.registries[this.registry_id].visible_signature_count;
        let loaded = Object.keys(this.signatures).length;
        return loaded >= total;
      }
    }
  },
  methods: {
    selectUpload() {
      document.getElementById('file_upload').click();
    },
    uploadSelected() {
      const uploaded_file = document.getElementById('file_upload').files[0];
      if (uploaded_file) {
          this.file = uploaded_file;
          this.upload_btn_text = 'Change File';
      }
    },
    downloadUrl(filename) {
      return process.env.VUE_APP_API_BASE_URL + '/uploads/' + filename;
    },
    sign() {
      // submit the form via the api
      this.$store.dispatch('addSignature', {
        registry_id: this.registry_id,
        name: this.name,
        document_id: ((this.document_id.length === 0) ? null : this.document_id),
        revision: ((this.revision.length === 0) ? null : this.revision),
        role: ((this.role.length === 0) ? null : this.role),
        behalf_of: ((this.behalf_of.length === 0) ? null : this.behalf_of),
        file: this.file,
        document_date: timestampFromDate(this.document_date),
        enable_verification: this.enable_verification,
        expiry_date: timestampFromDate(this.expiry_date),
      });
      // reset the form
      this.name = ''
      this.document_id = ''
      this.revision = ''
      this.role = ''
      this.behalf_of = ''
      this.file = null
      this.document_date = getDefaultDate()
      this.showing_batch = 0
      this.downloading = false
      this.upload_btn_text = 'Upload File'
      this.enable_verification = false
      this.expiry_date = null
    },
    revoke(id) {
      if (!this.signatures[id].revoked && this.signatures[id].owned) {
        this.$parent.show_modal(
          'Are you sure you want to revoke the signature "' + this.signatures[id]['name'] + '"?',
          () => {
            this.confirm_revoke(id);
          }
        );
      }
    },
    confirm_revoke(id) {
      this.$store.dispatch('revokeSignature', {
        registry_id: this.registry_id,
        id: id
      });
    },
    download() {
      if (this.registry_owner) {
        this.downloading = true;
        this.$store.dispatch('downloadRegistry', {
          registry_id: this.registry_id
        });
      }
    },
    loadRequired() {
      const verticalPosition = window.scrollY;
      const visibleHeight = document.documentElement.clientHeight;
      const contentHeight = document.documentElement.scrollHeight;
      return visibleHeight + verticalPosition >= contentHeight || contentHeight < visibleHeight;
    },
    onScroll() {
      if (this.loadRequired() && !this.all_loaded) {
        this.fetchNext(false);
      }
    },
    fetchNext(show_loader) {
      this.$store.dispatch('fetchSignatures', {
        show_loader: show_loader,
        registry_id: this.registry_id,
        batch: this.showing_batch
      });
      this.showing_batch += 1;
    },
  }
}

function getDefaultDate() {
  const now = new Date();
  let date = '';
  // Pad year with zeros to 4 digits.
  for (let i = 0; i < 4 - now.getFullYear().toString().length; i++) {
    date += '0';
  }
  date += now.getFullYear();
  date += '-';
  // Pad month with zeros to 2 digits.
  if (now.getFullMonth + 1 < 10) {
    date += '0';
  }
  date += now.getMonth() + 1;
  date += '-';
  // Pad date with zeros to 2 digits.
  if (now.getDate() < 10) {
    date += '0';
  }
  date += now.getDate();
  return date;
}

function timestampFromDate(date) {
  if (date === null) {
    return null;
  }

  const [year, month, day] = date.split('-');
  return new Date(year, month - 1, day).getTime() / 1000;
}
</script>

<template>
  <div>
    <h2 class="w3-center">{{ registry_name }}</h2>
    <h4 class="w3-center">Invite someone to the registry:</h4>
    <form v-on:submit.prevent="add_user" class="w3-center">
      <div>
        <input v-model="add_user_email" class="w3-input w3-border w3-light-grey w3-center" type="email" placeholder="Email Address" title="Email Address" required>
      </div>
      <div class="w3-padding">
        <button class="w3-button w3-theme-dark w3-hover-theme w3-round" type="submit">Add User</button>
      </div>
      <div v-if="response">
        <p class="w3-text-theme">{{ response }}</p>
      </div>
    </form>
    <div class="w3-container w3-padding"></div>
    <div class="w3-responsive">
      <table class="table-align-middle w3-table-all w3-centered">
        <tr class="w3-theme">
          <th class="w3-left-align">Name</th>
          <th class="w3-left-align">Email</th>
          <th></th>
        </tr>
        <tr v-for="email in ordered_user_emails" :key="email">
          <td class="w3-left-align">{{ users[email] }}</td>
          <td class="w3-left-align">{{ email }}</td>
          <td class="w3-right-align">
            <button v-if="email !== current_user" v-on:click="remove(users[email], email)" class="w3-button w3-round w3-theme-dark w3-hover-theme">Remove</button>
          </td>
        </tr>
      </table>
      <div v-if="!all_loaded" class="w3-center w3-padding">
        <button v-on:click="fetchNext(false)" class="w3-button w3-circle w3-theme"><i class="fa fa-caret-down"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      add_user_email: null,
      response: null,
      showing_batch: 0
    }
  },
  mounted() {
    this.fetchNext(true);
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll);
  },
  computed: {
    registry_id() {
      return this.$route.params.registry_id;
    },
    current_user() {
      return this.$store.state.user_info.email;
    },
    users() {
      if (this.$store.state.users[this.registry_id]) {
        return this.$store.state.users[this.registry_id];
      } else {
        return {};
      }
    },
    ordered_user_emails() {
      if (this.$store.state.users[this.registry_id]) {
        const users = this.$store.state.users[this.registry_id];
        let ordered = [];
        for (let email in users) {
          if (users.hasOwnProperty(email)) {
            ordered.push([users[email], email]);
          }
        }
        ordered = ordered.sort().map(item => {
          return item[1];
        });
        return ordered;
      } else {
        return [];
      }
    },
    registry_name() {
      if (this.$store.state.registries[this.registry_id]) {
        return this.$store.state.registries[this.registry_id].name;
      }
    },
    all_loaded() {
      if (this.$store.state.registries[this.registry_id]) {
        let total = this.$store.state.registries[this.registry_id].user_count;
        let loaded = Object.keys(this.users).length;
        return loaded >= total;
      }
    }
  },
  methods: {
    remove(name, email) {
      this.$parent.show_modal(
        'Are you sure you want to remove the user "' + name + '"?',
        () => {
          this.confirm_remove(email);
        }
      );
    },
    async confirm_remove(email) {
      this.$store.dispatch('removeUser', {
        registry_id: this.registry_id,
        user_email: email
      });
    },
    loadRequired() {
      const verticalPosition = window.scrollY;
      const visibleHeight = document.documentElement.clientHeight;
      const contentHeight = document.documentElement.scrollHeight;
      return visibleHeight + verticalPosition >= contentHeight || contentHeight < visibleHeight;
    },
    onScroll() {
      if (this.loadRequired() && !this.all_loaded) {
        this.fetchNext(false);
      }
    },
    async fetchNext(show_loader) {
      this.$store.dispatch('fetchUsers', {
        show_loader: show_loader,
        registry_id: this.registry_id,
        batch: this.showing_batch
      });
      this.showing_batch += 1;
    },
    async add_user() {
      this.response = null;
      this.response = await this.$store.dispatch('addUser', {
        registry_id: this.registry_id,
        user_email: this.add_user_email
      });
    }
  }
}
</script>

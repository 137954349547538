import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.filter('formatDateTimestamp', function(timestamp) {
  if (timestamp !== null) {
    // Calculate the offset from UTC by assuming that the timestamp was at midnight in the original time zone
    let offset = -(timestamp / 60 / 60) % 24;
    if (offset <= -12) {
      offset += 24;
    }
    if (offset > 12) {
      offset -= 24;
    }
    // Correct the timestamp to be midnight in UTC.
    timestamp += offset * 60 * 60;
    // Format the date with the offset.
    const dateString = new Date(timestamp * 1000).toLocaleDateString('en-au', { dateStyle: 'medium', timeZone: 'UTC' });
    const offsetString = `UTC${offset > 0 ? '+' : ''}${offset !== 0 ? offset : ''}`;
    return `${dateString} ${offsetString}`;
  }
});

Vue.filter('formatDateTimeTimestamp', function(timestamp) {
  if (timestamp !== null) {
    // Calculate the offset from UTC of the current client.
    const offset = new Date().getTimezoneOffset() / -60;
    // Format the date and time in current time zone and display the current time zone's offset.
    const dateString = new Date(timestamp * 1000).toLocaleString('en-au', { dateStyle: 'medium', timeStyle: 'medium' });
    const offsetString = `UTC${offset > 0 ? '+' : ''}${offset !== 0 ? offset : ''}`;
    return `${dateString} ${offsetString}`;
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

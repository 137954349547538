<template>
  <div id="app">
    <div class="w3-top">
      <div class="w3-bar w3-theme w3-padding w3-card">
        <router-link to="/registries" style="text-decoration:none;">
          <span class="w3-xlarge">Signature Registry</span>
        </router-link>
        <div class="w3-right w3-hide-small">
          <router-link v-if="$store.state.user_info === null && !auto_account_creation" to="/request" class="w3-bar-item w3-button w3-hover-white" title="Request Access"><i class="fa fa-envelope"></i></router-link>
          <router-link v-if="$store.state.user_info !== null" to="/search" class="w3-bar-item w3-button w3-hover-white" title="Search"><i class="fa fa-search"></i></router-link>
          <router-link to="/contact" class="w3-bar-item w3-button w3-hover-white" title="Contact"><i class="fa fa-phone-square"></i></router-link>
          <router-link v-if="$store.state.user_info !== null" to="/invite" class="w3-bar-item w3-button w3-hover-white" title="Invite"><i class="fa fa-envelope"></i></router-link>
          <button v-if="$store.state.user_info !== null" v-on:click="signOut" class="w3-bar-item w3-button w3-hover-white" title="Sign Out"><i class="fa fa-sign-out"></i></button>
        </div>
        <a class="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium" v-on:click="open_sidebar">
          <i class="fa fa-bars"></i>
        </a>
      </div>
    </div>
    <nav class="w3-sidebar w3-bar-block w3-theme-dark w3-card w3-animate-left w3-hide-medium w3-hide-large" style="display:none;z-index:3;" id="sidebar">
      <a v-on:click="close_sidebar" class="w3-bar-item w3-button w3-large w3-padding-16">Close &times;</a>
      <router-link to="/registries" v-on:click.native="close_sidebar" class="w3-bar-item w3-button">Home</router-link>
      <router-link v-if="$store.state.user_info === null" to="/request" v-on:click.native="close_sidebar" class="w3-bar-item w3-button">Request Access</router-link>
      <router-link v-if="$store.state.user_info !== null" to="/search" class="w3-bar-item w3-button w3-hover-white">Search</router-link>
      <router-link to="/contact" v-on:click.native="close_sidebar" class="w3-bar-item w3-button">Contact</router-link>
      <router-link v-if="$store.state.user_info !== null" to="/invite" v-on:click.native="close_sidebar" class="w3-bar-item w3-button">Invite</router-link>
      <button v-if="$store.state.user_info !== null" v-on:click="signOut" class="w3-bar-item w3-button">Sign Out</button>
    </nav>
    <div class="w3-overlay w3-hide-large" v-on:click="close_sidebar" style="cursor:pointer" id="overlay"></div>
    <div id="content" class="w3-auto">
      <div class="w3-padding"></div>
      <div v-show="!$store.state.loading" class="w3-card w3-round-large w3-white w3-animate-opacity">
        <div v-if="$store.state.user_info" class="w3-padding w3-small w3-center">
          <b>{{ $store.state.user_info.name }}</b> (<i>{{ $store.state.user_info.email }}</i>)
        </div>
        <router-view class="w3-padding"/>
        <div class="w3-padding"></div>
      </div>
    </div>
    <footer class="w3-container w3-center w3-display-bottommiddle w3-black">
      <div class="w3-xlarge w3-section">
        <a href="https://www.rgbassurance.com.au" rel="noopener" target="_blank"><i class="fa fa-external-link-square w3-hover-opacity w3-padding" title="RGB Assurance"></i></a>
        <a href="https://au.linkedin.com/company/rgb-assurance" rel="noopener" target="_blank"><i class="fa fa-linkedin w3-hover-opacity w3-padding" title="LinkedIn"></i></a>
      </div>
      <p>Copyright &copy; 2020. <a class="w3-hover-text-theme" href="https://www.rgbassurance.com.au" rel="noopener" target="_blank">RGB Assurance Pty Ltd.</a></p>
    </footer>
    <div id="loader" v-if="$store.state.loading" class="loader w3-display-middle"></div>
    <div v-if="modal_visible" class="w3-modal" style="display:block;">
      <div class="w3-modal-content w3-center">
        <header class="w3-container w3-theme-dark">
          <span v-on:click="hide_modal" class="w3-button w3-display-topright">&times;</span>
          <h2>Are you sure?</h2>
        </header>
        <div class="w3-container w3-padding">
          <p>{{ modal_message }}</p>
          <div class="w3-row">
            <div class="w3-half w3-container">
              <button v-on:click="confirm_modal" class="w3-button w3-green">Confirm</button>
            </div>
            <div class="w3-half w3-container">
              <button v-on:click="hide_modal" class="w3-button w3-theme-dark">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  html {
    height: 100%;
  }
  body {
    min-height: 100%;
    padding-bottom: 80px;
    background-image: url('/background.jpg');
    background-repeat: repeat-y;
    background-position: top;
    background-size: cover;
  }
  footer {
    width: 100%;
  }
  #content {
    padding-top: 54px;
    padding-bottom: 44px;
    width: 95%;
    max-width: 95%;
  }
  @media (min-width:992px) {
    #content {
      width: 85%;
      max-width: 85%;
    }
  }
  .logo {
    height: 36px;
    padding-left: 16px;
  }
  .table-align-middle td {
    vertical-align: middle;
  }
  p a {
    text-decoration: none;
  }
</style>

<script>
export default {
  data: function () {
    return {
      modal_visible: false,
      modal_message: '',
      model_on_confirm: function () {void(0);},
      auto_account_creation: process.env.VUE_APP_AUTO_ACCOUNT_CREATION === 'true'
    }
  },
  mounted() {
    if (this.$route.name !== 'verify') {
      this.$store.dispatch('getSessionInfo');
    }
  },
  methods: {
    async signOut() {
      this.$store.dispatch('logout');
    },
    open_sidebar() {
      document.getElementById('sidebar').style.display = 'block';
      document.getElementById('overlay').style.display = 'block';
    },
    close_sidebar() {
      document.getElementById('sidebar').style.display = 'none';
      document.getElementById('overlay').style.display = 'none';
    },
    show_modal(message, on_confirm) {
      this.modal_message = message;
      this.model_on_confirm = on_confirm;
      this.modal_visible = true;
    },
    confirm_modal() {
      this.modal_visible = false;
      this.model_on_confirm();
    },
    hide_modal() {
      this.modal_visible = false;
    }
  }
}
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import axios from 'axios';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

Vue.use(Vuex);

const request = axios.create({
  withCredentials: true
});

export default new Vuex.Store({
  state: {
    loading: true,
    user_info: null,
    registries: {},
    signatures: {},
    users: {}
  },
  mutations: {
    setUserInfo(state, user_info) {
      state.user_info = user_info;
    },
    setRegistries(state, registries) {
      state.registries = registries;
    },
    updateRegistryDetails(state, payload) {
      Vue.set(state.registries, payload.registry_id, payload.details);
    },
    setSignatures(state, payload) {
      if (!(payload.registry_id in state.signatures)) {
        Vue.set(state.signatures, payload.registry_id, {});
      }
      for (let id in payload.signatures) {
        if (payload.signatures.hasOwnProperty(id)) {
          Vue.set(state.signatures[payload.registry_id], id, payload.signatures[id]);
        }
      }
    },
    setSignature(state, payload) {
      Vue.set(state.signatures[payload.registry_id], payload.id, payload.signature);
    },
    setLoading(state, status) {
      state.loading = status;
    },
    setUsers(state, payload) {
      if (!(payload.registry_id in state.users)) {
        Vue.set(state.users, payload.registry_id, {});
      }
      for (let email in payload.users) {
        if (payload.users.hasOwnProperty(email)) {
          Vue.set(state.users[payload.registry_id], email, payload.users[email]);
        }
      }
    },
    removeUser(state, payload) {
      const new_user_count = state.registries[payload.registry_id]['user_count'] - 1;
      Vue.set(state.registries[payload.registry_id], 'user_count', new_user_count);
      Vue.delete(state.users[payload.registry_id], payload.user_email);
    },
    addUser(state, payload) {
      Vue.set(state.users[payload.registry_id], payload.user_email, payload.user_name);
    }
  },
  actions: {
    unauthorisedError() {
      location.assign('/');
    },
    async log_client_error(context, args) {
      // no need to catch errors or use response here
      // will fail if not authenticated, so don't include for login requests
      request.post(API_BASE_URL + '/error', {message: args.message});
    },
    async login(context, args) {
      context.commit('setLoading', true);
      const payload = {user_token: args.user_token};
      await request
        .post(API_BASE_URL + '/login', payload)
        .then(response => {
          if (Object.keys(response.data).length !== 0) {
            context.commit('setUserInfo', response.data);
            context.commit('setLoading', false);
            router.push({name: 'home'});
          } else {
            router.push({ name: 'request' });
            context.commit('setLoading', false);
          }
        })
        .catch(error => {
          context.commit('setLoading', false);
          router.push({ name: 'error' });
        });
    },
    async logout(context) {
      context.commit('setLoading', true);
      await request
        .post(API_BASE_URL + '/logout')
        .then(() => {
          location.assign('/');
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            context.commit('setLoading', false);
            context.dispatch('unauthorisedError');
          } else {
            context.commit('setLoading', false);
            router.push({ name: 'error' });
            context.dispatch('log_client_error', {message: 'Error encountered during logout POST request.'});
          }
        });
    },
    async getSessionInfo(context) {
      await request
        .get(API_BASE_URL + '/login')
        .then(response => {
          if (Object.keys(response.data).length !== 0) {
            context.commit('setUserInfo', response.data);
            if (router.currentRoute.name === 'login') {
              router.push({name: 'home'});
            }
          }
          context.commit('setLoading', false);
        })
        .catch(error => {
          // no need to action
          console.error('Error fetching session details.');
          context.commit('setLoading', false);
        });
    },
    async fetchRegistries(context) {
      context.commit('setLoading', true);
      await request
        .get(API_BASE_URL + '/registries')
        .then(response => {
          context.commit('setRegistries', response.data);
          context.commit('setLoading', false);
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            context.commit('setLoading', false);
            context.dispatch('unauthorisedError');
          } else {
            context.commit('setLoading', false);
            router.push({ name: 'error' });
            context.dispatch('log_client_error', {message: 'Error encountered during registries GET request.'});
          }
        });
    },
    async fetchSignatures(context, args) {
      if (args.show_loader) {
        context.commit('setLoading', true);
      }
      const payload = {
        registry_id: args.registry_id,
        batch: args.batch
      };
      await request
        .post(API_BASE_URL + '/signatures', payload)
        .then(response => {
          context.commit('updateRegistryDetails', {registry_id: response.data.id, details: response.data.details});
          context.commit('setSignatures', {registry_id: response.data.id, signatures: response.data.signatures});
          if (args.show_loader) {
            context.commit('setLoading', false);
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            if (args.show_loader) {
              context.commit('setLoading', false);
            }
            context.dispatch('unauthorisedError');
          } else {
            if (args.show_loader) {
              context.commit('setLoading', false);
            }
            router.push({ name: 'error' });
            context.dispatch('log_client_error', {message: 'Error encountered during signatures POST request.'});
          }
        });
    },
    async addSignature(context, args) {
      context.commit('setLoading', true);
      const payload = {
        registry_id: args.registry_id,
        name: args.name,
        document_id: args.document_id,
        revision: args.revision,
        role: args.role,
        behalf_of: args.behalf_of,
        document_date: args.document_date,
        enable_verification: args.enable_verification,
        expiry_date: args.expiry_date
      };
      let form_data = new FormData();
      form_data.append('data', JSON.stringify(payload));
      if (args.file !== null) {
        form_data.append('file', args.file);
      }
      await request
          .post(API_BASE_URL + '/sign', form_data)
          .then(response => {
            context.commit('setSignature', {
              registry_id: args.registry_id,
              id: response.data.id,
              signature: response.data.signature
            });
            context.commit('setLoading', false);
          })
          .catch(error => {
            if (error.response && error.response.status === 401) {
              context.commit('setLoading', false);
              context.dispatch('unauthorisedError');
            } else {
              context.commit('setLoading', false);
              router.push({ name: 'error' });
              context.dispatch('log_client_error', {message: 'Error encountered during sign POST request.'});
            }
          });
    },
    async revokeSignature(context, args) {
      const payload = {
        registry_id: args.registry_id,
        id: args.id
      };
      await request
        .post(API_BASE_URL + '/revoke', payload)
        .then(response => {
          context.commit('setSignature', {
            registry_id: args.registry_id,
            id: response.data.id,
            signature: response.data.signature
          });
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            context.dispatch('unauthorisedError');
          } else {
            context.commit('setLoading', false);
            router.push({ name: 'error' });
            context.dispatch('log_client_error', {message: 'Error encountered during revoke POST request.'});
          }
        });
    },
    async inviteUser(context, args) {
      const payload = {
        invite_email: args.invite_email
      };
      return await request
        .post(API_BASE_URL + '/invite', payload)
        .then(response => {
          return 'Your invite has been sent, they should receive an email shortly.';
        })
        .catch(error => {
          return "Sorry, we couldn't send your invite. Please check the email address is correct.";
        });
    },
    async requestAccess(context, args) {
      const payload = {
        name: args.name,
        email: args.email,
        message: args.message
      };
      return await request
        .post(API_BASE_URL + '/request', payload)
        .then(response => {
          return 'Your request will be reviewed shortly (please allow up to one week).';
        })
        .catch(error => {
          if (error.response && error.response.status === 403) {
            return "We're still working on your previous request. We'll get back to you shortly.";
          } else {
            return 'Sorry, we process your request. Please check that your email address is correct.';
          }
        });
    },
    async downloadRegistry(context, args) {
      // NOTE: will not work with streams
      const payload = {
        registry_id: args.registry_id
      };
      request
        .post(API_BASE_URL + '/download', payload)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.setAttribute('download', 'Signature Registry.csv');
          document.body.appendChild(link);
          link.click();
        }).catch(error => {
          context.commit('setLoading', false);
          router.push({ name: 'error' });
          context.dispatch('log_client_error', {message: 'Error encountered during download POST request.'});
      });
    },
    async fetchUsers(context, args) {
      if (args.show_loader) {
        context.commit('setLoading', true);
      }
      const payload = {
        registry_id: args.registry_id,
        batch: args.batch
      };
      await request
        .post(API_BASE_URL + '/users', payload)
        .then(response => {
          context.commit('updateRegistryDetails', {registry_id: response.data.id, details: response.data.details});
          context.commit('setUsers', {registry_id: response.data.id, users: response.data.users});
          if (args.show_loader) {
            context.commit('setLoading', false);
          }
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            if (args.show_loader) {
              context.commit('setLoading', false);
            }
            context.dispatch('unauthorisedError');
          } else {
            if (args.show_loader) {
              context.commit('setLoading', false);
            }
            router.push({ name: 'error' });
            context.dispatch('log_client_error', {message: 'Error encountered during users POST request.'});
          }
        });
    },
    async removeUser(context, args) {
      const payload = {
        registry_id: args.registry_id,
        user_email: args.user_email
      };
      await request
        .post(API_BASE_URL + '/remove', payload)
        .then(response => {
          context.commit('removeUser', {registry_id: args.registry_id, user_email: args.user_email});
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            context.dispatch('unauthorisedError');
          } else {
            context.commit('setLoading', false);
            router.push({ name: 'error' });
            context.dispatch('log_client_error', {message: 'Error encountered during remove POST request.'});
          }
        });
    },
    async addUser(context, args) {
      const payload = {
        registry_id: args.registry_id,
        user_email: args.user_email
      };
      return await request
        .post(API_BASE_URL + '/add', payload)
        .then(response => {
          context.commit('addUser', {
            registry_id: args.registry_id,
            user_email: args.user_email,
            user_name: response.data.name
          });
          return 'That user has been added, they should receive an email shortly.';
        })
        .catch(error => {
          if (error.response && error.response.status === 409) {
            return "That user already has access to this registry.";
          } else {
            return "Sorry, we couldn't send your invite. Please check the email address is correct.";
          }
        });
    },
    async search(context, args) {
      return await request
        .post(API_BASE_URL + '/search', {search_text: args.search_text})
        .then(response => {
          return response.data;
        })
        .catch(error => {
          return null;
        });
    },
    async verify(context, args) {
      return await request
        .post(API_BASE_URL + '/verify', {verification_id: args.verification_id})
        .then(response => {
          return response.data;
        })
        .catch(error => {
          return null;
        })
    }
  }
});

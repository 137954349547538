import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'

import Home from './views/Home.vue'
import Login from './views/Login.vue'
import Registry from './views/Registry.vue'
import Manage from './views/Manage.vue'
import Contact from './views/Contact.vue'
import Invite from './views/Invite.vue'
import Search from './views/Search.vue'
import RequestAccess from './views/RequestAccess.vue'
import Verify from './views/Verify.vue'
import PageNotFound from './views/PageNotFound.vue'
import Error from './views/Error.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: Login
  },
  {
    path: '/registries',
    name: 'home',
    component: Home
  },
  {
    path: '/search',
    name: 'search',
    component: Search
  },
  {
    path: '/registry/:registry_id',
    name: 'registry',
    component: Registry
  },
  {
    path: '/registry/:registry_id/manage',
    name: 'manage',
    component: Manage
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/request',
    name: 'request',
    component: RequestAccess
  },
  {
    path: '/invite',
    name: 'invite',
    component: Invite
  },
  {
    path: '/verify/:verification_id',
    name: 'verify',
    component: Verify
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
  {
    path: '*',
    name: 'notfound',
    component: PageNotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (from.name !== null) {
    const no_login_required = ['login', 'contact', 'request', 'notfound', 'error'];
    // if already authenticated and on the login or request screens, redirect home
    if ((to.name === 'login' || to.name === 'request') && store.state.user_info !== null) {
      next({ name: 'home' });
    } else if (!no_login_required.includes(to.name) && store.state.user_info === null) {
      // if not at the login screen and not authenticated, redirect to login
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router

<template>
  <div class="w3-center">
    <div class="w3-center w3-padding-32">
      <img id="logo" src="/inverse-logo.svg" alt="Logo">
    </div>
    <div id="sign-in" class="w3-center" style="min-height:44px;"></div>
    <hr>
    <div class="w3-padding feature-text">
      <p>
        RGB Assurance's Signature Registry is an easy to use registry for handwritten signatures. When you register a
        signature, a unique code is randomly generated for that particular document, signed on that particular day.
        You then write the code alongside your handwritten signature. It's that simple.
      </p>
      <p>
        This quick process verifies each signature that you sign, and ensures that it can't be copied without your
        consent. The Signature Registry acts as a valuable tool to verify the authenticity of your signatures.
      </p>
    </div>
    <hr>
    <div class="w3-row-padding">
      <div class="w3-half">
        <div class="w3-container">
          <h3 class="w3-text-theme">Personal Registry</h3>
        </div>
        <div class="w3-container">
          <p>
            <template v-if="auto_account_creation">You automatically receive a free personal registry when you first sign in.</template>
            <template v-else>A free personal registry is available <router-link to="/request" class="w3-text-theme">on request</router-link>.</template>
            Personal registries are visible only to your account.
          </p>
        </div>
      </div>
      <div class="w3-half">
        <div class="w3-container">
          <h3 class="w3-text-theme">Shared Registries</h3>
        </div>
        <div class="w3-container">
          <p>Businesses can share registries with their employees via our flexible and affordable subscription plans. At any time, users can download their registries for reporting, evidence, and archival purposes. Shared registry users also have the ability to upload documents alongside their signatures, to be stored securely within the registry.</p>
        </div>
      </div>
    </div>
    <div class="w3-row-padding">
      <div class="w3-half">
        <div class="w3-container">
          <h3 class="w3-text-theme">Mobile Friendly</h3>
        </div>
        <div class="w3-container">
          <p>RGB Assurance's Signature Registry is quick and easy to use on mobile, so you can sign documents when you're out of the office.</p>
        </div>
      </div>
      <div class="w3-half">
        <div class="w3-container">
          <h3 class="w3-text-theme">Pricing</h3>
        </div>
        <div class="w3-container">
          <p>
            <template v-if="auto_account_creation">Just sign in for a free personal registry!</template>
            <template v-else>Personal registries are free <router-link to="/request" class="w3-text-theme">on request</router-link>.</template>
            <br>
            <router-link class="w3-text-theme" to="/contact">Contact us</router-link> to enquire about shared registry pricing.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
  #logo {
    width: 80%;
    max-width: 400px;
    padding-bottom: 32px;
  }
  #sign-in iframe {
    margin: 0 auto !important;
  }
</style>

<script>
export default {
   data: function () {
    return {
      google_client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
      auto_account_creation: process.env.VUE_APP_AUTO_ACCOUNT_CREATION === 'true'
    }
  },
  mounted() {
    // load the google API
    const google_api = document.createElement('script');
    google_api.setAttribute('src', 'https://accounts.google.com/gsi/client');
    google_api.async = true;
    document.head.appendChild(google_api);
    // create the callback and show the button
    window.addEventListener('load', () => {
        window.google.accounts.id.initialize({
            client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
            callback: this.onSignin
        });
        window.google.accounts.id.renderButton(
            document.getElementById('sign-in'),
            {type: 'standard', theme: 'filled_blue', size: 'large', shape: 'rectangular', text: 'sign_in_with'}
        );
    })
  },
  methods: {
    async onSignin(user) {
      // start a session with the API
      this.$store.dispatch('login', {
        user_token: user.credential
      });
    }
  }
}
</script>

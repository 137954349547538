<template>
  <div>
    <br>
    <table v-if="response_date" class="table-align-middle w3-table-all w3-centered">
      <tr>
        <th class="w3-theme">Status</th>
        <td v-if="revoked" class="w3-red"><b>Revoked</b> (on {{ revoked_date | formatDateTimeTimestamp }})</td>
        <td v-else-if="expired" class="w3-amber"><b>Expired</b> (on {{ expiry_date | formatDateTimestamp }})</td>
        <td v-else class="w3-green"><b>Active</b> (as at {{ response_date.getTime() / 1000 | formatDateTimeTimestamp }})</td>
      </tr>
      <tr>
        <th class="w3-theme">Signatory</th>
        <td>{{ signatory }}</td>
      </tr>
      <tr>
        <th class="w3-theme">Document Name</th>
        <td>{{ name }}</td>
      </tr>
      <tr v-if="document_id">
        <th class="w3-theme">Document Identifier</th>
        <td>{{ document_id }}</td>
      </tr>
      <tr v-if="document_date">
        <th class="w3-theme">Document Date</th>
        <td>{{ document_date | formatDateTimestamp }}</td>
      </tr>
      <tr v-if="revision">
        <th class="w3-theme">Document Revision</th>
        <td>{{ revision }}</td>
      </tr>
      <tr v-if="expiry_date">
        <th class="w3-theme">Expiration Date</th>
        <td>{{ expiry_date | formatDateTimestamp}}</td>
      </tr>
    </table>
    <div v-else class="w3-center">
      <p>Invalid link.</p>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      response_date: null,
      signatory: null,
      name: null,
      document_date: null,
      document_id: null,
      revision: null,
      revoked: false,
      revoked_date: null,
      expiry_date: null,
      expired: null
    }
  },
  async mounted() {
    let document_data = await this.$store.dispatch('verify', {
      verification_id: this.verification_id
    });

    if (document_data !== null) {
      this.response_date = new Date();
      this.signatory = document_data.signatory;
      this.name = document_data.name;
      this.document_date = document_data.document_date;
      this.document_id = document_data.document_id;
      this.revision = document_data.revision;
      this.revoked = document_data.revoked;
      this.revoked_date = document_data.revoked_date;
      this.expiry_date = document_data.expiry_date;

      const now_epoch = Math.floor((new Date()).getTime() / 1000);
      this.expired = this.expiry_date !== null && now_epoch >= this.expiry_date;
    }

    this.$store.state.loading = false;
  },
  computed: {
    verification_id() {
      return this.$route.params.verification_id;
    }
  }
}
</script>

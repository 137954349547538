<template>
  <div class="w3-center">
    <h3>Request Access</h3>
    <p>If you don't already have access to the Signature Registry, you're welcome to <b>request free personal access</b>.</p>
    <p>We will review each request, then email you an invite if your request has been accepted.</p>
    <p><b>Your email address must be associated with a Google account</b>.<br>Sorry, we currently only accept login via Google.</p>
    <form v-on:submit.prevent="request" class="w3-container">
      <div class="w3-padding">
        <input v-model="name" class="w3-input w3-border w3-light-grey w3-center" type="text" placeholder="Name" title="Name" required>
      </div>
      <div class="w3-padding">
        <input v-model="email" class="w3-input w3-border w3-light-grey w3-center" type="email" placeholder="Email Address" title="Email Address" required>
      </div>
      <div class="w3-padding">
        <textarea v-model="message" class="noresize w3-input w3-border w3-light-grey w3-center" type="text" placeholder="How will you use the signature registry?" title="Message" rows="3" required></textarea>
      </div>
      <div class="w3-padding">
        <button id="send-btn" :disabled="requested" class="w3-button w3-theme-dark w3-hover-theme w3-round" type="submit">Request</button>
      </div>
      <div v-if="response !== null">
        <p class="w3-text-theme">{{ response }}</p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      name: '',
      email: '',
      message: '',
      response: null,
      requested: false
    }
  },
  methods: {
    async request() {
      this.requested = true;
      this.response = await this.$store.dispatch('requestAccess', {
        name: this.name,
        email: this.email,
        message: this.message
      });
    }
  }
}
</script>

<style>
  .noresize {
    resize: none;
  }
</style>

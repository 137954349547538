<template>
  <div class="w3-center">
    <h3>Contact Us</h3>
    <p>
      <b>RGB Assurance Pty Ltd</b><br>
      RGB House<br>
      236 Montague Road<br>
      West End QLD 4101
    </p>
    <p>
      <a href="mailto:info@rgbassurance.com.au?subject=Signature%20Registry">info@rgbassurance.com.au</a>
    </p>
    <div class="w3-padding-large">
      <iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=rgb%20assurance&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<template>
  <div class="w3-center">
    <h3>Invite someone to use the Signature Registry</h3>
    <form v-on:submit.prevent="send" class="w3-container">
      <div class="w3-padding">
        <input v-model="invite_email" class="w3-input w3-border w3-light-grey w3-center" type="email" placeholder="Email Address" title="Email Address" required>
      </div>
      <div class="w3-padding">
        <button id="send-btn" class="w3-button w3-theme-dark w3-hover-theme w3-round" type="submit">Send Invite</button>
      </div>
      <div v-if="response">
        <p class="w3-text-theme">{{ response }}</p>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      invite_email: '',
      response: null
    }
  },
  methods: {
    async send() {
      this.response = null;
      this.response = await this.$store.dispatch('inviteUser', {
        invite_email: this.invite_email
      });
    }
  }
}
</script>

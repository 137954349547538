<template>
  <div>
    <h2 class="w3-center">Search for a Signature</h2>
    <form v-on:submit.prevent="perform_search" class="w3-center">
      <div>
        <input v-model="search_text" class="w3-input w3-border w3-light-grey w3-center" type="text" placeholder="Search Text" title="Search Text" required>
      </div>
      <div class="w3-padding">
        <button class="w3-button w3-theme-dark w3-hover-theme w3-round" type="submit">Search</button>
      </div>
    </form>
    <div class="w3-center">
      <p>The most recent 100 results are shown. <i><router-link to="/contact">Contact us</router-link></i> if you require a more comprehensive search.</p>
    </div>
    <div v-if="error" class="w3-center">
      <p class="w3-text-red">Sorry, we couldn't perform the search you requested.</p>
    </div>
    <div v-show="searching" class="w3-center">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <div v-if="results !== null && !searching">
      <div v-if="results.length > 0" class="w3-responsive">
        <table class="table-align-middle w3-table-all w3-centered">
          <tr class="w3-theme">
            <th class="w3-left-align">User</th>
            <th class="w3-left-align">Name</th>
            <th>Document ID</th>
            <th>Revision</th>
            <th>Date</th>
            <th>Role</th>
            <th>On Behalf Of</th>
            <th>Code</th>
            <th>File</th>
            <th></th>
          </tr>
          <tr v-for="signature in results" :key="signature.code">
            <td class="w3-left-align" v-bind:class="{ revoked: signature.revoked }">{{ signature.user_name }}</td>
            <td class="w3-left-align" v-bind:class="{ revoked: signature.revoked }">{{ signature.name }}</td>
            <td v-if="signature.document_id" v-bind:class="{ revoked: signature.revoked }">{{ signature.document_id }}</td>
            <td v-else v-bind:class="{ revoked: signature.revoked }"><i>N/A</i></td>
            <td v-if="signature.revision" v-bind:class="{ revoked: signature.revoked }">{{ signature.revision }}</td>
            <td v-else v-bind:class="{ revoked: signature.revoked }"><i>N/A</i></td>
            <td v-if="signature.document_date" v-bind:class="{ revoked: signature.revoked }">{{ signature.document_date | formatDateTimestamp }}</td>
            <td v-else v-bind:class="{ revoked: signature.revoked }"><i>N/A</i></td>
            <td v-if="signature.role" v-bind:class="{ revoked: signature.revoked }">{{ signature.role }}</td>
            <td v-else v-bind:class="{ revoked: signature.revoked }"><i>N/A</i></td>
            <td v-if="signature.behalf_of" v-bind:class="{ revoked: signature.revoked }">{{ signature.behalf_of }}</td>
            <td v-else v-bind:class="{ revoked: signature.revoked }"><i>N/A</i></td>
            <td v-bind:class="{ revoked: signature.revoked }"><div class="code w3-wide w3-round"><h5><b>{{ signature.code }}</b></h5></div></td>
            <td>
              <a v-if="signature.filename" :href="downloadUrl(signature.filename)" rel="noopener" target="_blank">
                <i class="fa fa-file"></i>
              </a>
              <i v-else>N/A</i>
            </td>
            <td class="w3-right-align">
              <span v-if="signature.revoked">Revoked<br>{{ signature.revoked_date | formatDateTimeTimestamp }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div v-else>
        <p class="w3-center"><i>No results.</i></p>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .code {
    background-image: url('/serial.png');
  }
  .revoked {
    text-decoration: line-through;
  }
</style>

<script>
export default {
  data: function () {
    return {
      search_text: '',
      results: null,
      searching: false,
      error: false
    }
  },
  methods: {
    async perform_search() {
      this.searching = true;
      this.results = await this.$store.dispatch('search', {
        search_text: this.search_text
      });
      this.error = this.results === null;
      this.searching = false;
    },
    downloadUrl(filename) {
      return process.env.VUE_APP_API_BASE_URL + '/uploads/' + filename;
    }
  }
}
</script>

<template>
  <div>
    <h2 class="w3-center">Registries</h2>
    <div class="w3-responsive">
      <table class="table-align-middle w3-table-all w3-centered">
        <tr class="w3-theme">
          <th class="w3-left-align">Name</th>
          <th>Users</th>
          <th>My Signatures</th>
        </tr>
        <router-link v-for="id in registry_ids" :to="{path: '/registry/' + id}" tag="tr" class="w3-hover-text-theme pointer">
          <td class="w3-left-align">{{ registries[id].name }}</td>
          <td>{{ registries[id].user_count}}</td>
          <td>{{ registries[id].owned_signature_count}}</td>
        </router-link>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch('fetchRegistries');
  },
  computed: {
    registries() {
      return this.$store.state.registries;
    },
    registry_ids() {
      // default is to sort by string representation, so make integers then sort by value
      return Object.keys(this.$store.state.registries)
        .map(key => [this.$store.state.registries[key].name, key])
        .sort()
        .map(item => item[1]);
    }
  }
}
</script>
